import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";
import config from "./config";
import Account from "./Account";
import Terms from "./Terms";
import Privacy from "./Privacy";

function App() {
  const API_URL = config.API_URL;
  const STRIPE_URL = config.STRIPE_URL;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState("");
  const [timezone, setTimezone] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
  
    // Remove all non-numeric characters
    const cleanedInput = input.replace(/\D/g, "");
  
    // Limit input to 10 digits
    const formattedPhoneNumber = cleanedInput.substring(0, 10);
  
    // Format the phone number as (XXX) XXX - XXXX if there are enough digits
    let formatted = formattedPhoneNumber;
    if (formatted.length > 6) {
      formatted = `(${formatted.substring(0, 3)}) ${formatted.substring(3, 6)} - ${formatted.substring(6, 10)}`;
    } else if (formatted.length > 3) {
      formatted = `(${formatted.substring(0, 3)}) ${formatted.substring(3, 6)}`;
    } else if (formatted.length > 0) {
      formatted = `(${formatted.substring(0, 3)}`;
    }
  
    setPhoneNumber(formatted);
  };
  

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLanguageChange = (language) => {
    if (selectedLanguage === language) {
      setSelectedLanguage(null); // Uncheck if the same language is clicked again
    } else {
      setSelectedLanguage(language); // Set the selected language
    }
  };

  const handleSignupAndRedirect = async () => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, ""); // Strip non-digit characters
  
    if (cleanedPhoneNumber.length !== 10) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }
  
    if (!deliveryTime) {
      alert("Please select a valid delivery time.");
      return;
    }
  
    if (!timezone) {
      alert("Please select a valid timezone.");
      return;
    }
  
    if (!termsAgreed) {
      alert("You must agree to the Terms of Use to sign up.");
      return;
    }
  
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }
  
    const phoneNumberWithCountryCode = "1" + cleanedPhoneNumber;
  
    const payload = {
      Phone: phoneNumberWithCountryCode,
      Email: email,
      SelectedLanguage: selectedLanguage === "spanish" ? 1 : selectedLanguage === "french" ? 2 : 3, // 1 = Spanish, 2 = French, 3 = German
      DeliveryTime: deliveryTime,
      Timezone: timezone,
    };
  
    try {
      const response = await fetch(`${API_URL}/User/AddUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const responseData = await response.json();
  
        // Call gtagSendEvent before redirecting
        if (responseData.user_is_active === 0) {
          window.gtagSendEvent(STRIPE_URL); // Pass the STRIPE_URL to the function
        } else if (responseData.user_is_active === 1) {
          window.gtagSendEvent("/account"); // Pass the account page URL to the function
        }
      } else {
        const errorData = await response.json();
        alert(errorData.message || "Failed to add user. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    }
  };
  
  
  

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h2 className="App-signup-label">Daily Language Texts</h2>
          <img className="App-logo" src="http://dailylanguagetexts.com/images/languages.png" alt="Logo" />
        </header>

        <main className="App-main">
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  {" "}
                  <section className="App-user-info-section">
                    <h4 className="App-description">Get a text message langauge lesson every day.</h4>
                    <h4 className="App-description">Free 5 day trial - then $2.99/month</h4>
                    <img className="App-screen-img" src="http://dailylanguagetexts.com/images/phonescreen.png" alt="Example lesson" />
                    <h3 className="App-signup-label">Enter your phone number and email.</h3>
                    <div className="App-user-info-container">
                      <div className="App-phone-number-wrapper">
                        <span className="App-phone-prefix">+1</span>
                        <input
                          className="App-phone-number-input"
                          type="tel"
                          onChange={handlePhoneNumberChange}
                          value={phoneNumber}
                          placeholder="Enter your phone number"
                        />
                      </div>
                      <h4 className="App-phone-warning">(US based phone numbers only at this time.)</h4>
                      <input className="App-email-input" type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email" />
                    </div>
                  </section>
                  <section className="App-language-select-section">
                    <h3 className="App-language-select-label">Select the language you want to learn.</h3>
                    <div className="App-language-select-container">
                      <label className="App-language-checkbox-label">
                        <input
                          className="App-language-checkbox"
                          type="checkbox"
                          value="spanish"
                          checked={selectedLanguage === "spanish"}
                          onChange={() => handleLanguageChange("spanish")}
                        />
                        Spanish
                      </label>
                      <label className="App-language-checkbox-label">
                        <input
                          className="App-language-checkbox"
                          type="checkbox"
                          value="french"
                          checked={selectedLanguage === "french"}
                          onChange={() => handleLanguageChange("french")}
                        />
                        French
                      </label>
                      <label className="App-language-checkbox-label">
                        <input
                          className="App-language-checkbox"
                          type="checkbox"
                          value="german"
                          checked={selectedLanguage === "german"}
                          onChange={() => handleLanguageChange("german")}
                        />
                        German
                      </label>
                    </div>
                  </section>
                  <section className="App-delivery-timezone-section">
                    <h3 className="App-time-select-label">Select your delivery time and time zone.</h3>
                    <div className="App-delivery-timezone-selectors">
                      <label className="App-delivery-time-label">
                        Delivery Time:
                        <select value={deliveryTime} onChange={(e) => setDeliveryTime(e.target.value)}>
                          <option value="">Select Time</option>
                          {Array.from({ length: 24 }, (_, i) => (
                            <option key={i} value={i}>
                              {i === 0 ? "12:00 AM" : i === 12 ? "12:00 PM" : i < 12 ? `${i}:00 AM` : `${i - 12}:00 PM`}
                            </option>
                          ))}
                        </select>
                      </label>

                      <label className="App-timezone-label">
                        Timezone:
                        <select value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                          <option value="">Select Timezone</option>
                          <option value="Eastern Standard Time">Eastern Standard Time (EST)</option>
                          <option value="Central Standard Time">Central Standard Time (CST)</option>
                          <option value="Mountain Standard Time">Mountain Standard Time (MST)</option>
                          <option value="Pacific Standard Time">Pacific Standard Time (PST)</option>
                        </select>
                      </label>
                    </div>
                  </section>
                  <div className="App-terms-checkbox">
                    <label>
                      <input type="checkbox" checked={termsAgreed} onChange={(e) => setTermsAgreed(e.target.checked)} />
                      Click to agree to the{" "}
                      <a href="/terms" target="_blank">
                        Terms of Use
                      </a>{" "}
                      and to receive messages.
                    </label>
                  </div>
                  <button className="App-phone-number-submit-button" onClick={handleSignupAndRedirect}>
                    Submit
                  </button>
                </div>
              }
            />
            <Route path="/account" element={<Account />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </main>
        <nav>
          <Link to="/">Home</Link> | <Link to="/account">Account</Link> | <Link to="/terms">Terms</Link> | <Link to="/privacy">Privacy</Link>
        </nav>
      </div>
    </Router>
  );
}

export default App;
