import React from "react";
import "./Privacy.css";

function Privacy() {
  return (
    <div className="privacy">
      <h1>PRIVACY POLICY</h1>
      <h2>Daily Language Texts Privacy Policy</h2>
      <p>Last Updated September 25, 2024</p>
      <p>
        This Privacy Policy applies to your access and use of all products and services that are made available through the Daily Language Texts website,
        located at www.dailylanguagetexts.com (the “Site”), and is incorporated into and is subject to the Daily Language Texts{" "}
        <a href="/terms">Terms of Use</a> (the “Terms”). Capitalized terms that are not defined in the Privacy Policy have the meaning given to them in the
        Terms.
      </p>
      <p>
        This Privacy Policy only applies to information collected on the Site and is not intended to fully describe the privacy policies of Daily Language
        Texts. It describes the information that we gather from you, how we use and disclose your information, and the steps we take to protect your
        information. By using the Site, you consent to the privacy practices described in this Policy.
      </p>

      <p>
        <strong>The information that we collect:</strong>
      </p>
      <ul>
        <li>
          <p>
            <strong>Personal Information</strong>
          </p>
          <p>
            Generally, you can visit the Site without telling us who you are or revealing any Personal Information (when we use the term “Personal Information,”
            we mean information that can be associated with a specific person and can be used to identify that person, such as name, e-mail address, mailing
            address, mobile phone number, age, gender, date of birth, as well as additional sensitive information such as your social security number, financial
            information, financial account information, and other similar types of information). If you do provide us with Personal Information, we will store
            and use that information in accordance with this Policy. For example, you may provide us with Personal Information when you fill out forms or fields
            on the Site or use the Services; when you register for a Daily Language Texts account and/or service; when you participate in online surveys; and
            when you contact us or request information about us, this Site, and our products or services (whether by email or other means).
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies and Local Storage Information</strong>
          </p>
          <p>
            When you use the Site, we may send one or more Cookies (which are small text files containing a string of alphanumeric and/or other characters) to
            your computer or mobile device, to help analyze our web page flow, customize our content, measure promotional effectiveness, and promote trust and
            safety. You are always free to decline our cookies if your browser permits, although doing so may interfere with your ability to use the Site or
            certain features of the Site. We may also use Google Analytics or a similar service that uses Cookies to help us analyze how users use the Site. In
            addition, we or a third-party service (e.g., Google Analytics, Twitter, etc.) may store limited information on the local storage of your device.
          </p>
        </li>
        <li>
          <p>
            <strong>Automatically Collected Information</strong>
          </p>
          <p>
            When you visit the Site, we may automatically receive and record certain information from your computer, web browser and/or mobile device, including
            your IP address or other device address or ID, web browser and/or device type, hardware and software settings and configurations, the web pages or
            sites that you visit just before or just after visiting the Site, the pages you view on the Site, your actions on the Site, and the dates and times
            that you visit, access, or use the Site. When you use the Site on a mobile device, we may also collect the physical location of your device by, for
            example, using satellite, cell phone tower, or wireless local area network signals. When you use the Site or sign up for Services we may determine
            your time zone based on your IP address.
          </p>
        </li>
        <li>
          <p>
            <strong>Payment Information</strong>
          </p>
          <p>
            When you register for a Paid Subscription we utilize a third-party payment processor,{" "}
            <a href="https://stripe.com" target="_blank">
              Stripe
            </a>
            . They may in turn allow you to utilize various other payment instruments, e.g., Apple Pay. Each of those third-parties is separate from Daily
            Language Texts and their privacy policies and terms will govern how they collect, store, utilize and share your information. They may share certain,
            generally limited, payment information with us in order to confirm your orders and/or payments. We primarily maintain all information provided by
            third-parties on their servers under their primary control. Based on Stripe’s practice they may send or provide some of that information to us, for
            instance via email receipts from Stripe, this may include for instance the last four digits of your credit card, your billing zip code, and/or other
            identifying information you provided to them.
          </p>
        </li>
      </ul>

      <p>
        <strong>How we use the information we collect:</strong>
      </p>
      <ul>
        <li>
          <p>
            We use non-identifying information about your use of the Site to understand and analyze the usage trends and preferences of our users, to improve
            the Site, and to improve fraud detection and information security.
          </p>
        </li>
        <li>
          <p>
            We may use your e-mail address, phone number or other Personal Information (a) to contact you for administrative purposes such as customer service,
            and/or to send you promotional materials; (b) to provide Services; (c) for payment purposes, e.g., to confirm payment or process refunds. Generally,
            you can opt-out of receiving promotional communications by following links provided in the messages, by updating your account preferences through
            the Services, or by contacting us directly at{" "}
            <a href="mailto:support@dailylanguagetexts.com" target="_blank">
              support@dailylanguagetexts.com
            </a>
            . Although your changes are reflected promptly in active user databases, we may retain all information you submit for a variety of purposes,
            including backups and archiving, prevention of fraud and abuse, and analytics.
          </p>
        </li>
        <li>
          <p>
            We may use Cookies Information, local storage information, and Automatically Collected Information to: (a) personalize our services, such as
            remembering your information so that you will not have to re-enter it during your visit or the next time you visit the Site or use the Services; (b)
            provide customized content and information; (c) monitor and analyze the effectiveness of the Site and/or Services; (d) provide services; (e) embed
            or interact with third-party services via the Site; and (f) monitor aggregate site usage metrics such as total number of visitors and pages viewed.
          </p>
        </li>
      </ul>

      <p>
        <strong>When we disclose information:</strong>
      </p>
      <ul>
        <li>
          <p>
            We may disclose your information if required to do so by law or in the good-faith belief that such action is necessary to take precautions against
            liability; if we believe it is necessary to contact social services based on the content of communications provided to the Company; to protect Daily
            Language Texts from fraudulent, abusive, or unlawful uses or activity; to protect the security or integrity of the Site; to investigate and defend
            ourselves against any third-party claims or allegations; to assist government enforcement agencies; or to comply with state and federal laws, in
            response to a court order, judicial or other government subpoena or warrant.
          </p>
        </li>
        <li>
          <p>
            We make certain information available to third parties to comply with various reporting obligations, and for business or marketing purposes. When
            possible, we provide this information in a de-identified way.
          </p>
        </li>
        <li>
          <p>
            We work with third party service providers to provide website development, hosting, data storage, maintenance, payment processing, and other
            services for us. To the extent it is necessary for these third party service providers to complete their contractual obligations to us, these third
            parties may have access to or process your information.
          </p>
        </li>
        <li>
          <p>
            In the event Daily Language Texts were to be engaged in or contemplating a divestiture, merger, consolidation, or asset sale, or in the unlikely
            event of a bankruptcy, Daily Language Texts may transfer or assign the data, including Personal Information, that we have collected from users.
          </p>
        </li>
      </ul>

      <p>
        <strong>Your Choices:</strong>
      </p>
      <ul>
        <li>
          <p>
            You may, of course, decline to share certain information with us, in which case we may not be able to provide to you some of the features and
            functionality of the Site and/or Services.
          </p>
        </li>
        <li>
          <p>
            Once you have registered for a Daily Language Texts account, you may update, correct, or delete your profile information and preferences at any time
            by contacting{" "}
            <a href="mailto:support@dailylanguagetexts.com" target="_blank">
              support@dailylanguagetexts.com
            </a>
            . Or, by texting the Service back ‘unsubscribe.’
          </p>
        </li>
        <li>
          <p>
            If you have not created an account and you would like for us to delete your information in our possession, please send us a request at{" "}
            <a href="mailto:support@dailylanguagetexts.com" target="_blank">
              support@dailylanguagetexts.com
            </a>
            .
          </p>
        </li>
        <li>
          <p>
            If you do not wish to receive email offers or newsletters and/or SMS/text messages from us, you can opt-out of receiving email and/or SMS/text
            information from us (except for emails related to the completion of your registration, correction of user data, change of password, and other
            similar communications essential to your transactions through the Site) by using the unsubscribe process at the bottom of the email or texting
            ‘unsubscribe’ in response to any text. Although your changes are reflected promptly in active user databases, we may retain all information you
            submit for a variety of purposes, including backups and archiving, prevention of fraud and abuse, and analytics.
          </p>
        </li>
      </ul>

      <p>
        <strong>Our Commitment to Children’s Privacy:</strong>
      </p>
      <p>
        Protecting the privacy of young children is especially important. For that reason, we do not allow children under 13 years of age to use the Site or
        Services, we do not knowingly collect or maintain information from persons under 13 years of age, and no part or parts of the Site are directed to
        persons under 13 years of age. If you are under 13 years of age, then please do not use or access the Site or Services at any time or in any manner. If
        we learn that information has been collected through the Site or Services from persons under 13 years of age and without verifiable parental consent,
        then we will take the appropriate steps to delete this information in our possession. If you are a parent or guardian and discover that your child under
        13 years of age has obtained a Daily Language Texts account or provided us with information, please alert us at{" "}
        <a href="mailto:support@dailylanguagetexts.com" target="_blank">
          support@dailylanguagetexts.com
        </a>{" "}
        and request that we delete the information from our systems.
      </p>

      <p>
        <strong>Our Commitment to Data Security:</strong>
      </p>
      <p>
        We use certain physical, managerial, and technical safeguards that are designed to protect the integrity and security of your information; however, no
        security measures are perfect or impenetrable, so we cannot ensure or warrant the security of any information you transmit to us through the Site or
        Services, and you do so at your own risk. We also cannot guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach
        of any of our physical, technical, or managerial safeguards. We cannot control the actions of other users or other third parties with whom you may
        choose to share your information and therefore we cannot and do not guarantee that information you post through the Site or submit via the Services will
        not be viewed by unauthorized persons. We are not responsible for circumvention of any privacy settings or security measures contained on the Site or
        within the Services. Even after removal, copies of information that you have posted may remain viewable in cached and archived pages or if other users
        have copied or stored such information.
      </p>
      <p>
        We retain information as long as it is reasonably necessary and relevant for our operations, and/or to comply with the law, prevent fraud, collect any
        fees owed, resolve disputes, troubleshoot problems, assist with any investigation, or enforce our Terms or other agreements.
      </p>

      <p>
        <strong>Visitors from Outside the United States:</strong>
      </p>
      <p>
        This Site is controlled and operated by Daily Language Texts in the United States. If you choose to access the Site and/or Services from outside the
        United States, you acknowledge that you will be transferring your information, including Personal Information, outside of those regions to the United
        States for storage and processing, as necessary to provide to you the products and services available through the Site; and, that your data may flow
        through non-U.S. networks in the process. Where required and possible, we comply with applicable legal frameworks relating to the collection, storage,
        use, and transfer of personal information.
      </p>
      <p>
        Depending on the jurisdiction in which you reside, you may have the right to access, correct, delete, or restrict use of certain Personal Information
        covered by this Privacy Policy. Depending on the jurisdiction, you may also have the right to request that we refrain from processing your Personal
        Information. Please bear in mind that if you exercise such rights this may affect our ability to provide our products and services. For inquiries about
        your Personal Information, please contact us by e-mail at{" "}
        <a href="mailto:support@dailylanguagetexts.com" target="_blank">
          support@dailylanguagetexts.com
        </a>
        , or through any of the contact information provided below. While Daily Language Texts will make reasonable efforts to accommodate your request, we also
        reserve the right to impose certain restrictions and requirements on such requests, if allowed or required by applicable laws. Please note that it may
        take some time to process your request, consistent with applicable law.
      </p>

      <p>
        <strong>Limitation of Liability:</strong>
      </p>
      <p>
        By accessing this Site and/or any Services and/or providing us with Personal Information and other data, you expressly and unconditionally release and
        hold us harmless from any and all liability for any injuries, loss, or damage of any kind arising from or in connection with the use and/or misuse of
        such information. In addition, while we take efforts to ensure the proper and appropriate use of data by our service providers that may receive your
        information from us, we are not responsible for any injuries, loss, or damage of any kind arising from or in connection with the use and/or misuse of
        your information caused by those service providers.
      </p>

      <p>
        <strong>Changes and Updates to this Privacy Policy:</strong>
      </p>
      <p>
        We reserve the right to make changes to this Privacy Policy at any time. We will notify you about significant changes in the way we treat your
        information, including by placing a prominent notice on the Site or by sending you an email or text messages so that you can choose whether to continue
        using the Site and/or Services. Material modifications are effective 30 calendar days after our initial notification or upon your acceptance of the
        modified Terms. Immaterial modifications are effective upon posting of the updated Privacy Policy or Terms of Service on the Site. Please revisit this
        page periodically to stay aware of any changes to this Privacy Policy. For the avoidance of doubt, disputes arising hereunder will be resolved in
        accordance with the Privacy Policy in effect at the time the dispute arose.
      </p>

      <p>
        <strong>Do Not Track Disclosures:</strong>
      </p>
      <p>
        Third parties such as advertising networks, analytics providers, and widget providers may collect information about your online activities over time and
        across different websites when you access or use our Services. Currently, various browsers offer a “Do Not Track” option, but there is no standard for
        how DNT should work on commercial websites. Due to lack of such standards, the Site does not respond to Do Not Track consumer browser settings.
      </p>

      <p>
        <strong>Our Contact Information:</strong>
      </p>
      <p>
        Please contact us with any questions or comments about this Privacy Policy, your personal information, our use and disclosure practices, or your consent
        choices by e-mail at{" "}
        <a href="mailto:support@dailylanguagetexts.com" target="_blank">
          support@dailylanguagetexts.com
        </a>
        .
      </p>
    </div>
  );
}

export default Privacy;
