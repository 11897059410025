import React from "react";
import config from "./config";

function Account() {
    const STRIPE_CUSTOMER_PORTAL = config.STRIPE_CUSTOMER_PORTAL;
  return (
    <div>
      <div style={{ marginTop: "20px" }}>
      <span style={{ marginRight: "10px" }}>
          To change your payment method or cancel your subscription
        </span>
        <button
          onClick={() => window.location.href = STRIPE_CUSTOMER_PORTAL}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Click here
        </button>
      </div>
    </div>
  );
}

export default Account;