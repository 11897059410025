import React from "react";
import "./Terms.css";

function Terms() {
  return (
    <div className="terms">
      <h1>TERMS OF USE</h1>
      <h2>Daily Language Texts, Inc. Terms &amp; Conditions</h2>
      <h2>Last Updated and Effective as of: September 25, 2024</h2>
      <p>
        <strong>
          Daily Language Texts’ messages, services, and www.dailylanguagetexts.com (the "Site") are owned and operated by Daily Language Texts, Inc.
          (hereinafter “Daily Language Texts,” “we,” “our,” or “us”).
        </strong>{" "}
        These Terms and Conditions, together with Daily Language Texts’{" "}
        <a href="/privacy_policy" target="_blank">
          Privacy Policy
        </a>{" "}
        (incorporated herein by reference), apply to the messages, website/s, applications, systems, social media accounts, and other services (collectively,
        the “Services”) made available to you.
      </p>
      <p>
        These Terms and Conditions, including the Subscriber/Subscriptions-specific Terms and Refund Policy included below, set forth the terms and conditions
        (“Terms”) that apply to your use of or access to any Services offered by Daily Language Texts, including without limitation the website
        www.dailylanguagetexts.com and/or receipt of text, SMS, and/or MMS messages from us. “Daily Language Texts, Inc.” includes Daily Language Texts, Inc.
        &amp; www.dailylanguagetexts.com, and its officers, directors, employees, consultants, affiliates, subsidiaries, and agents.
      </p>
      <p>
        By using or accessing the Services you agree to these Terms, as updated from time to time in accordance with Section 12 below. Because Daily Language
        Texts provides a variety of services, we may from time to time ask you to review and accept supplemental or updated terms that apply to your interaction
        with the Services broadly or a specific product or service, including for example the Subscriber Specific Terms and/or Refund Policy included at the
        conclusion of these Terms.
      </p>
      <p>
        <strong>These Terms state that any disputes between you and</strong> Daily Language Texts, Inc.{" "}
        <strong>must be resolved in arbitration or small claims court.</strong>
      </p>
      <p>
        If you have questions feel free to reach out to{" "}
        <a href="mailto:support@dailylanguagetexts.com" target="_blank">
          support@dailylanguagetexts.com
        </a>
        .
      </p>
      <p>
        <strong>1. Use of the Services.</strong> The Services are provided for personal, non-commercial, and informational purposes only. Any other use requires
        prior written consent from us.
      </p>
      <p>
        <strong>2. Account Eligibility.</strong> To use the Site or Services, you must (i) be at least eighteen (18) years of age; (ii) have not previously been
        suspended or removed from the Services; and (iii) register for and use the Services in compliance with any and all applicable laws and regulations. At
        our sole discretion we may terminate any account or revoke access to the Services at any time. You are solely responsible for ensuring that your access
        to and use of the Services accords and complies with any and all laws, local or otherwise, which may apply to you. Where these Terms or your access and
        use of the Services are prohibited or in conflict with any applicable law, rule, or regulation your right to access the Services is revoked.
      </p>
      <p>
        <strong>3. Account Registration.</strong> To access the Services, you may be required to register for an account. To register for an account, we may ask
        you to give us certain identifying information about yourself, including but not limited to your email address, phone number, time zone, and other
        contact information, and we may ask you to create a username and password (“Registration Information”). When registering for and maintaining an account,
        you agree to provide true, accurate, current, and complete information about yourself. You also agree not to impersonate anyone, misrepresent any
        affiliation with anyone else, use false information, or otherwise conceal your identity from Daily Language Texts, Inc. for any purpose. You are solely
        responsible for maintaining the confidentiality and security of your password and other Registration Information. For your protection and the protection
        of other users, we ask you not to share your Registration Information with anyone else. If you do share this information with anyone, we will consider
        their activities to have been authorized by you. If you have reason to believe that your account is no longer secure, you must immediately notify us at:{" "}
        <a href="mailto:support@dailylanguagetexts.com" target="_blank">
          support@dailylanguagetexts.com
        </a>
        .
      </p>
      <p>
        <strong>4. Specific Requirements for Certain Services.</strong> Your use of the Services constitutes your acknowledgment and acceptance of the following
        specific requirements and terms of use for certain of the Daily Language Texts, Inc. services. For example, to receive text messages from Daily Language
        Texts Inc. you must provide a viable phone number that you control. To receive emails you may need to provide a reliable email address which you
        control. If you download and use a mobile application to access Daily Language Texts, Inc services, you may be subject to certain App Store Terms.
      </p>
      <p>
        <strong>5. Consent to receive text and SMS/MMS messages.</strong> When you provide a phone number to receive messages you are{" "}
        <strong>
          expressly consenting to receive messages from Daily Language Texts, including messages that may be deemed automated, marketing, and/or promotional in
          nature or otherwise covered by the Telephone Consumer Protection Act.
        </strong>{" "}
        You may opt out of receiving messages at any time by replying ‘unsubscribe’ to any text message, or emailing{" "}
        <a href="mailto:support@dailylanguagetexts.com" target="_blank">
          support@dailylanguagetexts.com
        </a>{" "}
        and requesting that your number be removed from the recipient list.
      </p>
      <p>
        <strong>6. Prohibited Conduct.</strong> You agree not to:
        <ul>
          <li>Use the Site or Services for any illegal purpose, or in violation of any local, state, national, or international law;</li>
          <li>Violate or encourage others to violate the rights of third-parties, including intellectual property rights;</li>
          <li>
            Post, upload, or distribute any content that is unlawful, defamatory, libelous, inaccurate, or that a reasonable person could deem to be
            objectionable, profane, indecent, pornographic, harassing, threatening, hateful, or otherwise inappropriate;
          </li>
          <li>Interfere in any way with security-related features of the Site or Service;</li>
          <li>
            Interfere with the operation or any user’s enjoyment of the Site or Service, including by uploading or otherwise disseminating viruses, adware,
            spyware, worms, or other malicious code, making unsolicited offers or advertisements to other users, or attempting to collect personal information
            about users or third-parties without their consent;
          </li>
          <li>
            Access, monitor, or copy any content or information of the Site or Service using any robot, spider, scraper, or other automated means or any manual
            process for any purpose without Daily Language Texts, Inc.’s express written permission;
          </li>
          <li>
            Perform any fraudulent activity, including impersonating any person or entity, claiming false affiliations, accessing the accounts of other users
            without permission, or falsifying your identity or any information about you, including age or date of birth;
          </li>
          <li>Sell or otherwise transfer the access or services granted herein;</li>
          <li>Spam the reply phone number by text, email, phone call, or any other means;</li>
          <li>Repeatedly sign up and unsubscribe; or,</li>
          <li>Sign-up with the information, including without limitation phone number or email, of another person or entity which you do not control.</li>
        </ul>
      </p>
      <p>
        <strong>7. Third-Party Content.</strong> The Site or messages sent by us may contain links to third-party websites and services. Daily Language Texts
        provides such links as a convenience, and does not control or endorse these websites and services. You acknowledge and agree that Daily Language Texts
        has not reviewed the content, advertising, products, services, or other materials that appear on such third-party websites or services, and is not
        responsible for the legality, accuracy, or appropriateness of any such content.
      </p>
      <p>
        <strong>8. Intellectual Property.</strong> You acknowledge and agree that you relinquish all ownership rights in any ideas or suggestions that you
        submit to Daily Language Texts through this Site, any service connected to the Company, including email or text message. Whether Daily Language Texts is
        or is not actively soliciting feedback, input or ideas, if you submit any idea you are expressly foregoing any intellectual property or ownership right
        in said ideas by submitting them to Daily Language Texts.
      </p>
      <p>
        The Site is protected by applicable copyright and other intellectual property laws, and no materials from the Site may be copied, reproduced,
        republished, uploaded, posted, transmitted, or distributed in any way without our express permission. All trademarks and service marks on the Site
        belong to Daily Language Texts, except third-party trademarks or service marks, which are the property of their respective owners.
      </p>
      <p>
        <strong>9. Content.</strong> You represent and warrant that you own or otherwise have the right to use any content you post to the Site or submit to
        Daily Language Texts with the intent that it be used or posted by the Company.
      </p>
      <p>If you believe that your content has been used in a way that constitutes copyright infringement, you may contact us:</p>
      <p>
        <a href="mailto:support@dailylanguagetexts.com" target="_blank">
          support@dailylanguagetexts.com
        </a>
      </p>
      <p>
        You must provide the following information: an electronic or physical signature of the copyright owner or the person authorized to act on behalf of the
        copyright owner; a description of the copyrighted work that you claim has been infringed; a description of where the material that you claim is
        infringing is located; your address, telephone number, and email address; a written statement that you have a good faith belief that the disputed use is
        not authorized by the copyright owner, its agent, or the law; a statement by you, made under penalty of perjury, that the above information in your
        notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf. Daily Language Texts has adopted and
        implements a policy that provides for termination in appropriate circumstances of accounts of users who repeatedly infringe copyright or other
        intellectual property rights of Daily Language Texts or others.
      </p>
      <p>
        <strong>10. Indemnification.</strong> You agree that you will be personally responsible for your use of the Site and Services, and you agree to defend,
        indemnify, and hold harmless Daily Language Texts from and against any and all claims, liabilities, damages, losses, and expenses (including attorneys’
        and accounting fees and costs), arising out of or in any way connected with (i) your access to, use of, or alleged use of the Site and/or Services; (ii)
        your violation of the Terms or any applicable law or regulation; (iii) your violation of any third-party right, including without limitation any
        intellectual property right, publicity, confidentiality, property, or privacy right; or (iv) any disputes or issues between you and any third-party.
        Daily Language Texts reserves the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to
        indemnification by you, and in such case, you agree to cooperate with our defense of such claim.
      </p>
      <p>
        <strong>11. Termination.</strong> If you violate these Terms, your permission to use the Site and/or Services will automatically terminate. In addition,
        Daily Language Texts, in its sole discretion, may suspend or terminate your user account and/or suspend or terminate some or all of your access to the
        Services at any time, with or without notice to you. You may terminate your account at any time by contacting Daily Language Texts at{" "}
        <a href="mailto:support@dailylanguagetexts.com" target="_blank">
          support@dailylanguagetexts.com
        </a>{" "}
        or in the case of the text messages replying “unsubscribe” to any text message we send you. After your account is terminated, information and content
        previously provided by you will no longer be accessible through your account, but Daily Language Texts may continue to store such information and
        content, and it may also be stored by third-parties to whom it has been transferred through any of your use or uses of the Site or Services.
      </p>
      <p>
        <strong>12. Modification of the Terms.</strong> Daily Language Texts reserves the right at any time to modify these Terms and to impose new or
        additional terms or conditions on your use of the Site and/or Services. Such modifications and additional terms and conditions will be effective
        immediately upon notice and incorporated into these Terms. We will make reasonable efforts to notify you of any material changes to the Terms,
        including, but not limited to, by posting a notice to our website or by sending an email or text message to any address or phone number you may have
        provided to us. Your continued use of the Services following notice will be deemed acceptance of any modifications to the Terms.
      </p>
      <p>
        <strong>13. Disclaimers of Warranties.</strong> The Site and Services are provided “as is” and on an “as available” basis, without warranty or condition
        of any kind, either express or implied. Although Daily Language Texts seeks to maintain safe, secure, accurate, and well-functioning services, we cannot
        guarantee the continuous operation of or access to our Site or Services, and there may at times be inadvertent technical or factual errors or
        inaccuracies. Daily Language Texts specifically (but without limitation) disclaims (i) any implied warranties of merchantability, fitness for a
        particular purpose, quiet enjoyment, or non-infringement; and (ii) any warranties arising out of course-of-dealing, usage, or trade. You assume all risk
        for any/all damages that may result from your use of or access to the Site or Services. Daily Language Texts does not guarantee the accuracy of, and
        disclaims all liability for, any errors or other inaccuracies in the information, content, recommendations, and materials made available through the
        Site.
      </p>
      <p>
        <strong>14. Limitation of Liability.</strong> In no event will Daily Language Texts be liable to you for any incidental, special, consequential, direct,
        indirect, or punitive damages, whether based on warranty, contract, tort (including negligence), statute, or any other legal theory, whether or not
        Daily Language Texts has been informed of the possibility of such damage. Some jurisdictions do not allow the disclaimer of warranties or limitation of
        liability in certain circumstances. Accordingly, some of the above limitations may not apply to you.
      </p>
      <p>
        <strong>15. Governing Law.</strong> These Terms are governed by the laws of the State of Delaware (DE), without regard to conflict of law principles.
        Subject to Section 14, which provides that disputes are to be resolved through binding arbitration or small claims court, to the extent that any lawsuit
        or court proceeding is permitted hereunder, you and Daily Language Texts agree to submit to the exclusive personal jurisdiction of the state courts and
        federal courts located within the county of New Castle, in the state of Delaware, for the purpose of litigating all such disputes.
      </p>
      <p>
        <strong>16. Dispute Resolution by Binding Arbitration.</strong> In the interest of resolving disputes between you and Daily Language Texts in the most
        expedient and cost effective manner,{" "}
        <strong>
          you and Daily Language Texts agree to resolve disputes through binding arbitration or small claims court instead of in courts of general jurisdiction
        </strong>{" "}
        (“Agreement to Arbitrate”). Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury,
        allows for more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief that
        a court can award. Any arbitration or litigation under these Terms will take place on an individual basis; class arbitrations and class actions are not
        permitted. You acknowledge and agree that the arbitrator may award relief (including monetary, injunctive, and declaratory relief) only in favor of the
        individual party seeking relief and only to the extent necessary to provide relief necessitated by that individual party’s claim(s). Any relief awarded
        cannot affect other users.
      </p>
      <p>
        <strong>17. Modification of the Site and Services.</strong> Daily Language Texts reserves the right to modify or discontinue, temporarily or
        permanently, some or all of the Site and/or Services at any time without any notice or further obligation to you. You agree that Daily Language Texts
        will not be liable to you or to any third-party for any modification, suspension, or discontinuance of any of the Site or Services.
      </p>
      <p>
        <strong>18. General.</strong>
      </p>
      <ul>
        <li>
          A. <u>Entire Agreement.</u> These Terms, together with the{" "}
          <a href="/privacy" target="_blank">
            Privacy Policy
          </a>
          , Subscriber Specific Terms and Refund Policy (both included below), constitute the entire and exclusive understanding and agreement between you and
          Daily Language Texts regarding your use of and access to the Site and/or Services, and except as expressly permitted above may only be amended by a
          written agreement signed by authorized representatives of the parties.
        </li>
        <li>
          B. <u>No Waiver.</u> The failure to require performance of any provision shall not affect our right to require performance at any time thereafter, nor
          shall a waiver of any breach or default of the Terms constitute a waiver of any subsequent breach or default or a waiver of the provision itself.
        </li>
        <li>
          C. <u>Paragraph Headers.</u> Use of paragraph headers in the Terms is for convenience only and shall not have any impact on the interpretation of
          particular provisions.
        </li>
        <li>
          D. <u>Severability.</u> In the event that any part of the Terms is held to be invalid or unenforceable, the unenforceable part shall be given effect
          to the greatest extent possible and the remaining parts will remain in full force and effect.
        </li>
      </ul>
      <p>
        <strong>19. Notice to California Residents.</strong> Under California Civil Code Section 1789.3, you may contact the Complaint Assistance Unit of the
        Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California
        95834, or by telephone at (800) 952-5210 in order to resolve a complaint regarding the service or to receive further information regarding use of the
        service.
      </p>
      <hr />
      <p id="refund-policy">
        <strong>Refund Policy</strong>
      </p>
      <p>
        Our Refund Policy is subject to all our Terms and is incorporated by reference therein. Where any part of the Refund Policy appears to contradict other
        Terms, those Terms will govern.
      </p>
      <p>
        If you have an issue with your Daily Language Texts Subscription, we are happy to assist or answer questions. There are, however, restrictions on
        whether we are able to offer you a refund. For questions please contact us via email at{" "}
        <a href="mailto:support@dailylanguagetexts.com" target="_blank">
          support@dailylanguagetexts.com
        </a>
        .
      </p>
      <p>
        <strong>Key:</strong> You must contact us within 30 days of a charge in order to be <strong>eligible</strong> for a refund. We do not grant refunds for
        any charges that are more than 30 days old. Contacting us within 30 days does not guarantee we will issue a refund, but if you contact us about a charge
        more than 30 days old we will not be able to provide a refund.
      </p>
      <p>
        We process refunds to the same payment method you used to make the purchase. We do not provide refunds via cash, check, or other payment instruments or
        external payment methods. We can only provide refunds for purchases and payments that have happened within 30 days of the request for refund, and only
        to the original card and/or account.
      </p>
      <p>
        <strong>Conditions for a Refund</strong>
      </p>
      <p>
        <u>Technical Issues, Payment Errors, Fraud</u>
      </p>
      <p>Daily Language Texts will issue refunds for purchases made within 30 days of the request for a refund in the following situations:</p>
      <ul>
        <li>
          Technical issues - If a technical issue that is our fault prevented you from effectively utilizing the Services, in our sole discretion, we may
          provide you with an extension or replacement for any such purchased content or subscription time, this may include the original requested content or
          different sources as we deem suitable. If we cannot or choose not to provide an extension or a replacement, we'll issue you a refund.
        </li>
        <li>
          Fraud - If you can show to our satisfaction or we can determine that your account has or was taken over by a third-party and used fraudulently to
          incur a charge or fee from us, we will issue you a refund, unless we determine that your card, bank, payment service or our third-party payment
          processor is determined to be able to offer the refund; even in that circumstance we may offer a refund.
        </li>
        <li>Payment error - If we charged you in error, based on our determination, we'll issue a refund.</li>
      </ul>
      <p>You must contact us within 30 days of a disputed charge for us to be able to issue a refund.</p>
      <p>
        <u>Unused subscription refund requirement</u>
      </p>
      <p>
        If you've used the service for part of the billing cycle and then canceled, you will not be eligible to receive a refund for the charge. You will only
        receive refunds if we determine the above conditions apply.
      </p>
      <p>
        Again, you must contact us within 30 days of any disputed charge, regardless of whether you have used your purchase. We cannot, under any circumstances,
        give you a refund for charges that are more than 30 days old. Restrictions are placed on transactions after 30 days and refunds cannot be issued after
        that time.
      </p>
      <p>
        <u>How to request a refund</u>
      </p>
      <p>
        If you believe your purchase meets the above requirements including the fact that the transaction in question occurred within the last 30 days, contact{" "}
        <a href="mailto:support@dailylanguagetexts.com" target="_blank">
          support@dailylanguagetexts.com
        </a>{" "}
        so we can determine if we agree the requirements have been met and can issue you a refund for the charge.
      </p>
      <p>Please include as much information on the purchase as possible to help us locate the purchase quickly for you. Ideally, include:</p>
      <ul>
        <li>The Stripe receipt number; this can be found in the email receipt sent at the time of purchase</li>
        <li>Your phone number and the email address</li>
        <li>The amount of the purchase</li>
        <li>The date and time the charge was made</li>
        <li>
          If you were charged by credit card: the last four digits of the card number (please for security reasons, do not include the full number), the type of
          card (Visa, MasterCard, etc.), and the name that appears on the card
        </li>
      </ul>
      <p>Please note:</p>
      <p>
        We process payments using{" "}
        <a href="https://stripe.com" target="_blank">
          Stripe
        </a>
        . Stripe’s{" "}
        <a href="https://stripe.com/legal" target="_blank">
          terms and policies
        </a>{" "}
        also apply to the transaction and may limit or control the method you may receive a refund as we only offer refunds via the payment method and
        processor, Stripe, you used to purchase a Subscription. We are not responsible for any issues arising between you and third-parties such as Stripe.
      </p>
      <p>
        <u>Reservation of Rights</u>
      </p>
      <p>
        We reserve the right to refuse a refund request if we reasonably believe (i) that you are trying to unfairly exploit this refund policy; (ii) if you are
        in breach of our Terms; or (iii) if we reasonably suspect that you are using our service fraudulently.
      </p>
      <p>This Refund Policy section of the Terms does not affect your statutory rights.</p>
    </div>
  );
}

export default Terms;
