const config = {
  // Prod configs
  API_URL: "https://dailylanguagetexts.azurewebsites.net/api",
  STRIPE_URL: "https://buy.stripe.com/5kA6qg09I7D2adi144",
  STRIPE_CUSTOMER_PORTAL: "https://billing.stripe.com/p/login/5kA5kGehI53B6MocMM"

  //Test configs
  // API_URL: "https://localhost:7164/api",
  // STRIPE_URL: "https://buy.stripe.com/test_7sI7tpaRuejP9nWeUU",
  // STRIPE_CUSTOMER_PORTAL: "https://billing.stripe.com/p/login/test_14k6opgs84Gjg3S4gg"
};

export default config;
